.spinner {
  background: url("../../Icons/iconSpinner.gif") no-repeat center center;
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: 9999999;
}

.main_spinner {
  position: absolute;
  z-index: 9999999;
  background: #ffffffd4;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}

.main_spinner span {
  margin-top: 4rem;
  font-style: italic;
  font-size: 11px;
}
