.daily-log-settings {
  .time-picker,
  .select-daily-sync {
    width: 100% !important;
  }

  // .daily-log-settings-content {
  //   padding: 28px !important;
  // }
}

.daily-log-settings .jr-card {
  width: 300px ;
}

.daily-log-settings .card-presentational {
  max-width: 400px !important;
  margin-right: 0px !important
}
