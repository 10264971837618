.procore-import-project{
  display: flex;
  align-items: center;

  .card-import-project{
    width: 100%;
    margin-bottom: 0;
  }

  //.merge-button-project{
  //  position: absolute;
  //  right: 1%;
  //  top: 6%;
  //  z-index: 1;
  //}
}